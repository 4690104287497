'use client'

import { useEffect } from 'react'
import { Crisp } from 'crisp-sdk-web'

const CrispEmbed = () => {
	useEffect(() => {
		if (process.env.NEXT_PUBLIC_CRISP_CHAT_WEBSITE_ID) {
			Crisp.configure(process.env.NEXT_PUBLIC_CRISP_CHAT_WEBSITE_ID)
		}
	}, [])

	return null
}

export default CrispEmbed
